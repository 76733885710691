/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useReducer } from 'react';
import Meats from "../icons/meats"
import Fishs from "../icons/fishs"
import Milk from "../icons/milk"
import Oil from "../icons/oil"
import Vegetable from "../icons/vegetable"
import Grains from "../icons/grains"
import AutoSelectInput from "./autoselect"
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import data_itens from '../../data/datav1'
//import data_detail from '../../data/data_itensv1'
import rowsData from '../../../data/all_data_obj_min'

// https://stackoverflow.com/a/58666400
// https://stackoverflow.com/a/61192638

const Vpg = 2
const enerId = 1
const macroIds = [4, 5, 6, 7]
const saisIds = [15, 16, 17, 18, 19, 20, 21, 22, 23];
const vitaIds = [25, 26, 27, 28, 29, 30, 31, 32, 33, 34];
//const vitaIDs = [[24, 25], 26, 27, 28, 29, 30, 31, 32, 33, 34];
const vIds = ['kcal', 'macro', 'vita', 'sal']
const nutriIds = [1].concat(macroIds, saisIds, vitaIds)
const nutriInitialState = {"1":0, "4":0, "5":0, "6":0, "7":0, "15":0, "16":0, "17":0, "18":0, "19":0, "20":0, "21":0, "22":0, "23":0, "25":0, "26":0, "27":0, "28":0, "29":0, "30":0, "31":0, "32":0, "33":0, "34":0}

const roundOne = (n, d) => Math.round(n * Math.pow(10, d)) / Math.pow(10, d)

function nutriReducer(state, action) {

  const dataAlm = rowsData.alimentos.filter(val => {
    if (val["1"] === action.id) {
      return val
    }
  })[0]
  
  const inputBox = document.getElementById(action.boxId) 
  const dataOld = inputBox.getAttribute('data-old')

  const dataAlmOld = rowsData.alimentos.filter(val => {
    if (val["1"] === dataOld) {
      return val
    }
  })[0]

  const dataPrev = inputBox.getAttribute('data-prev')
  const nutriNewState = {"1":0, "4":0, "5":0, "6":0, "7":0, "15":0, "16":0, "17":0, "18":0, "19":0, "20":0, "21":0, "22":0, "23":0, "25":0, "26":0, "27":0, "28":0, "29":0, "30":0, "31":0, "32":0, "33":0, "34":0}
  //console.log(typeof dataOld)

  switch (dataPrev) {

    case null:
      nutriIds.map(ide => {
        nutriNewState[ide] = state[ide] + dataAlm[ide+4]//data_detail[action.id][ide].valorpg
        nutriNewState[ide] = roundOne(nutriNewState[ide], 5)
        document.getElementById('nutri_'+ide+'_value').innerHTML = nutriNewState[ide]
        inputBox.setAttribute('data-old', action.id)
        inputBox.setAttribute('data-prev', 1)
        //console.log('null', ide, state[ide], data_detail[action.id][ide].valorpg, nutriNewState[ide])
      })
      return nutriNewState;

    case 1:
      nutriIds.map(ide => {
        const datadiff = state[ide] - dataAlmOld[ide+4]
                
        if (action.remove) {
          inputBox.removeAttribute('data-old')
          inputBox.removeAttribute('data-prev')
          nutriNewState[ide] = datadiff

        } else {
          inputBox.setAttribute('data-old', action.id)
          nutriNewState[ide] = datadiff + dataAlm[ide+4]

        }

        nutriNewState[ide] = roundOne(nutriNewState[ide], 5)
        document.getElementById('nutri_'+ide+'_value').innerHTML = nutriNewState[ide]

      })
      return nutriNewState;

    default:
      nutriIds.map(ide => {
        const datadiff = state[ide] - dataAlmOld[ide+4]

        if (action.remove) {
          inputBox.removeAttribute('data-old')
          inputBox.removeAttribute('data-prev')
          nutriNewState[ide] = datadiff

        } else {
          inputBox.setAttribute('data-old', action.id)
          nutriNewState[ide] = datadiff + dataAlm[ide+4]

        }

        nutriNewState[ide] = roundOne(nutriNewState[ide], 5)
        document.getElementById('nutri_'+ide+'_value').innerHTML = nutriNewState[ide]

        // const datadiff = state[ide] - dataAlmOld[ide+4]//data_detail[dataOld][ide].valorpg
        // nutriNewState[ide] = datadiff + dataAlm[ide+4]//data_detail[action.id][ide].valorpg
        // nutriNewState[ide] = roundOne(nutriNewState[ide], 5)
        // document.getElementById('nutri_'+ide+'_value').innerHTML = nutriNewState[ide]
        // inputBox.setAttribute('data-old', action.id)
        //console.log('prev', ide, state[ide], data_detail[dataOld][ide].valorpg, data_detail[action.id][ide].valorpg, nutriNewState[ide])
      })
      return nutriNewState;
  }
}

const useStyles = makeStyles((theme) => ({
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  firstChild: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  nChild: {
    paddingBottom: theme.spacing(2),
  }
}));

export default function ComboBox(props) {
  const classes = useStyles();
  const [nurtiState, nutriDispatch] = useReducer(nutriReducer, nutriInitialState);
  const ad1Print = (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign:'center'}}>
        {
          props.ad1 ?
          <img src={props.ad1} class="mx-auto" /> :
          ''
        }
        
      </Grid>
    </>
  )
  const ad2Print = (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{textAlign:'center'}}>
        {
          props.ad2 ?
          <img src={props.ad2} class="mx-auto" /> :
          ''
        }
        
      </Grid>
    </>
  )
  return (
    <>
    <Grid container elevation={0}>
      <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
        <AutoSelectInput
          BoxId={'slc-carnes'}
          Name={'Carnes e derivados'}
          Icon={<Meats styles={classes.icon}/>}
          Data={data_itens.carnes}
          Dispatch={nutriDispatch}
          className={classes.firstChild}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
        <AutoSelectInput
          BoxId={'slc-peixes'}
          Name={'Peixes e frutos do mar'}
          Icon={<Fishs styles={classes.icon}/>}
          Data={data_itens.pescados}
          Dispatch={nutriDispatch}
          className={classes.firstChild}
        />
      </Grid>

      {ad1Print}

      <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
        <AutoSelectInput
          BoxId={'slc-cereais'}
          Name={'Cereais, Leguminosas e farinhas'}
          Icon={<Grains styles={classes.icon}/>}
          Data={data_itens.cereais}
          Dispatch={nutriDispatch}
          className={classes.firstChild}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
        <AutoSelectInput
          BoxId={'slc-vegetais'}
          Name={'Vegetais e frutas'}
          Icon={<Vegetable styles={classes.icon}/>}
          Data={data_itens.vegetais}
          Dispatch={nutriDispatch}
          className={classes.firstChild}
        />
      </Grid>

      {ad2Print}

      <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
        <AutoSelectInput
          BoxId={'slc-oleos'}
          Name={'Óleos e sementes'}
          Icon={<Oil styles={classes.icon}/>}
          Data={data_itens.gorduras}
          Dispatch={nutriDispatch}
          className={classes.firstChild}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
        <AutoSelectInput
          BoxId={'slc-leite'}
          Name={'Leite e derivados'}
          Icon={<Milk styles={classes.icon}/>}
          Data={data_itens.leite}
          Dispatch={nutriDispatch}
          className={classes.firstChild}
        />
      </Grid>
    </Grid>
    </>
  );
}