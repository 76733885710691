/* eslint-disable no-use-before-define */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

export default function AutoSelectInput(props) {
  
  return (
      <Grid container elevation={0} spacing={12} alignItems="center" className={props.className}>
        <Grid item elevation={0} xs={2} sm={2} md={1} lg={1} xl={1} style={{textAlign:'center'}}>
          {props.Icon}
        </Grid>
        <Grid item elevation={0} xs={10} sm={10} md={11} lg={11} xl={11}>
          <Autocomplete
          fullWidth
          size="medium"
          id={props.BoxId}
          options={props.Data}
          getOptionLabel={(option) => option.nome}
          renderInput={(params) => <TextField {...params}
                                              label={props.Name}
                                              variant="outlined"
                                              style={{width:'100%'}}
                                              />
                      }
          onChange={
            (event, value) => {

              var remv = false
              
              if (!value) {
                const tst = document.getElementById(props.BoxId).getAttribute('data-old')
                value = {"id": tst}
                remv = true
              }
              
              props.Dispatch({boxId: props.BoxId, id: value.id, remove: remv})
            }
          }
          //onInputChange={(event, value) => {testFunc(value)}}
          //disableClearable
          />
        </Grid>
      </Grid>
  );
}